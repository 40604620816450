import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import Select from "react-select"
import SEO from "../../components/seo"
import usePagination from "../../hooks/usePagination"
import { marketreportURL } from "../../lib/urls"
import { capitalFirst } from "../Common/utils"
import GreenPropertyCardBadge from "../GreenPropertyCardBadge/GreenPropertyCardBadge"
import InnerPagination from "../InnerPagination/InnerPagination"
import "./MarketReportListing.scss"
import GGFXImage from "../../modules/GGFXImage"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const MarketReportListing = ({
  add_page_modules,
  marketReportData,
  strapi_id,
  areaData,
  partData,
}) => {
  let queryOption
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    const community = result?.get("community")
    queryOption = {
      label: capitalFirst(community?.replace(/-/g, " ")),
      value: community,
    }
  }

  let periodOption
  if (typeof window !== "undefined") {
    const result1 = new URLSearchParams(window.location?.search)
    const period = result1?.get("period")
    periodOption = {
      label: capitalFirst(period)?.replace("-", " "),
      value: period,
    }
  }

  let allAreaOption = {
    label: "All Area",
    value: "all",
  }
  let allPartOption = {
    label: "Time Period",
    value: "all",
  }

  let queryPeriodData = ""
  let queryData = ""
  let periodData = ""


  if (
    queryOption?.value &&
    queryOption?.value !== "all" &&
    periodOption?.value &&
    periodOption?.value !== "all"
  ) {
    let filteredArea1 = marketReportData.filter(area => {
      if (area.node.areas?.length > 0) {
        return area?.node?.areas?.some(c => c.slug?.includes(queryOption.value))
      }
    })
    let filteredAreatab2 = filteredArea1.filter(area => {
      if (area.node.quarter?.slug) {
        return area?.node?.quarter?.slug===periodOption.value
      }
    })
    queryPeriodData = filteredAreatab2
  } else if (queryOption?.value && queryOption?.value !== "all") {
    queryData = marketReportData.filter(area => {
      if (area.node.areas?.length > 0) {
        return area?.node?.areas?.some(c => c.slug?.includes(queryOption.value))
      }
    })
  } else if (
    periodOption?.value &&
    periodOption?.value !== null &&
    periodOption?.value !== "all"
  ) {
    periodData = marketReportData.filter(area => {
      if (area.node.quarter?.slug) {
        return area?.node?.quarter?.slug===periodOption.value
      }
    })
  }

  const [search, setSearch] = useState("")
  const [selectedArea, setSelectedArea] = useState(
    queryPeriodData?.length > 0
      ? queryPeriodData
      : periodData?.length > 0
      ? periodData
      : queryData?.length > 0
      ? queryData
      : marketReportData
  )
  const [selectedOption, setSelectedOption] = useState(
    queryOption?.label && queryOption?.label !== "All"
      ? queryOption
      : allAreaOption
  )
  const [selectedOptionPart, setSelectedOptionPart] = useState(
    periodOption?.label && periodOption.label !== "All"
      ? periodOption
      : allPartOption
  )

  areaData.sort((a, b) => a.title.localeCompare(b.title))
  let areaOption = []
  areaOption.push(allAreaOption)
  // console.log(areaData)
  areaData?.forEach(element => {
    if (element.title) {
      areaOption.push({
        label: element.title,
        value: element.slug,
      })
    }
  })

  let partOption = []
  partOption.push(allPartOption)
  // console.log(areaData)
  partData?.forEach(element => {
    if (element.node.title) {
      partOption.push({
        label: element.node.title,
        value: element.node.slug,
      })
    }
  })

  // for pagination
  // selectedArea?.sort(
  //   (a, b) => new Date(b.node?.publishedAt) - new Date(a.node?.publishedAt)
  // )
  const itemsPerPage = 16
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedArea,
    itemsPerPage,
  })

  // for pagination


  const handleChange = event => {
    setSelectedOption(allAreaOption)
    setSelectedOptionPart(allPartOption)

    setSearch(event.target.value)
    let marketReportList = marketReportData

    // if (selectedOption.value !== "all" && event.target.value === "") {
    //   marketReportList = marketReportData.filter(area => {
    //     if (area.node.title) {
    //       return area.node.title.includes(selectedOption.value)
    //     }
    //   })
    // }

    // if (selectedOption.value !== "all" && event.target.value) {
    //   marketReportList = marketReportData.filter(area => {
    //     if (area.node.title) {
    //       return area.node.title.includes(selectedOption.value)
    //     }
    //   })
    // }

    if (event.target.value) {
      let marketReport = marketReportList.filter((area, index) =>
        // area.node?.title
        //   ?.toLowerCase()
        //   .slice(0, event.target.value.length) ===
        // event.target.value.toLowerCase()
        area.node?.title
          ?.toLowerCase()
          ?.includes(event.target.value.toLowerCase())
      )

      setSelectedArea(marketReport)
    } else {
      setSelectedArea(marketReportList)
    }
    navigate(`/resources/market-reports`)
    setCurrentPage(1)

  }

  
  const handleTabChange = option => {
    setSelectedOption(option)

    navigate(
      `${selectedOptionPart?
      `?community=${option?.value}&period=${selectedOptionPart?.value}`
      :`?community=${option?.value}`
    }`)
    setSearch("")
    // setSelectedOptionPart(allPartOption)
    if (option.value === "all") {
      if (selectedOptionPart?.value !== "all") {
        let filteredAreatab = marketReportData.filter(area => {
          if (area.node.quarter?.slug) {
            return area?.node?.quarter?.slug===selectedOptionPart?.value
            
          }
        })

        setSelectedArea(filteredAreatab)
        setCurrentPage(1)
      } else {
        setSelectedArea(marketReportData)
      }
      return
    } else {
      if (selectedOptionPart?.value !== "all") {
        let filteredArea = marketReportData.filter(area => {
          if (area.node.areas?.length > 0) {
            return area?.node?.areas?.some(c =>
              // c.slug?.includes(queryOption.value)
                 c.slug===option.value
            )
          }
        })
        let filteredAreatab = filteredArea.filter(area => {
          if (area.node.quarter?.slug) {
            // return area?.node?.quarter?.slug?.includes(
            //   selectedOptionPart?.value
             return area?.node?.quarter?.slug===selectedOptionPart?.value
          }
        })
        setSelectedArea(filteredAreatab)
        setCurrentPage(1)
      } else {
        let filteredArea = marketReportData.filter(area => {
          if (area.node.areas?.length > 0) {
            return area?.node?.areas?.some(c => c.slug?.includes(option.value))
          }
        })

        setSelectedArea(filteredArea)
        setCurrentPage(1)
      }
    }
  }

  const handlePartChange = option => {
    setSearch("")
    navigate(
      `${
        selectedOption
          ? `?community=${selectedOption?.value}&period=${option?.value}`
          : `?period=${option?.value}`
      }`
    )
    // setSelectedOption(allAreaOption)
    if (typeof window !== "undefined") {
      sessionStorage.setItem("market_period", JSON.stringify(option))
    }
    setSelectedOptionPart(option)
    if (option.value === "all") {
      if (selectedOption?.value !== "all") {
        let filteredAreatab = marketReportData.filter(area => {
          if (area.node.areas?.length > 0) {
            return area?.node?.areas?.some(c =>
              c.slug?.includes(selectedOption?.value)
            )
          }
        })
        setSelectedArea(filteredAreatab)
        setCurrentPage(1)
      } else {
        setSelectedArea(marketReportData)
      }
      return
    } else {
      if (selectedOption?.value !== "all") {
        let filteredArea = marketReportData.filter(area => {
          if (area.node.quarter?.slug) {
            return area?.node?.quarter?.slug===option.value
          }
        })
        let filteredAreatab = filteredArea.filter(area => {
          if (area.node.areas?.length > 0) {
            return area?.node?.areas?.some(c =>
              c.slug?.includes(selectedOption?.value)
            )
          }
        })

        setSelectedArea(filteredAreatab)
        setCurrentPage(1)
      } else {
        let filteredArea = marketReportData.filter(area => {
          if (area.node.quarter?.slug) {
            return area?.node?.quarter?.slug===option.value
          }
        })

        setSelectedArea(filteredArea)
        setCurrentPage(1)
      }
    }
  }

  var imagename = "market-report.tail_image.list_image"

  //dynamic meta;

  let defaultArea = "Dubai"
  let seoArea = selectedOption?.label ? selectedOption.label : defaultArea
  let seoTimePeriod = selectedOptionPart?.label ? selectedOptionPart.label : ""

  let seoTitle = `${defaultArea} Market report`
  let seoDesc = `Stay on top of the latest trends and news with ${defaultArea} Market report`

  if (
    selectedOption.label &&
    selectedOptionPart?.label &&
    selectedOption.value !== "all" &&
    selectedOptionPart.value !== "all"
  ) {
    seoTitle = `${seoArea} + ${seoTimePeriod} Market report`
    seoDesc = `Stay on top of the latest trends and news with ${seoArea} + ${seoTimePeriod} Market report`
  } else if (selectedOption.label && selectedOption.value !== "all") {
    seoTitle = `${seoArea} Market report`
    seoDesc = `Stay on top of the latest trends and news with ${seoArea} Market report`
  } else if (selectedOptionPart?.label && selectedOptionPart.value !== "all") {
    seoTitle = `${seoTimePeriod} Market report`
    seoDesc = `Stay on top of the latest trends and news with ${seoTimePeriod} Market report`
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={seoTitle}
        titleTemplate={`%s | haus & haus`}
        meta={[
          {
            name: `description`,
            content: seoDesc,
          },
          {
            property: `og:title`,
            content: seoTitle,
          },
          {
            property: `og:description`,
            content: seoDesc,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: seoTitle,
          },

          {
            name: `twitter:description`,
            content: seoDesc,
          },
          {
            name: `facebook-domain-verification`,
            content: ``,
          },
        ]}
      >
        <SEO
          title={seoTitle ? seoTitle : ""}
          description={seoDesc ? seoDesc : ""}
        />
      </Helmet>
      <div className="marketreport-listing-wrapper">
        <Container>
          <div className="marketreport-sorting-section">
            <ScrollAnimation
              className="market-animation"
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              <Row>
                <Col xs={12} md={6} xl={4} className="search-fix">
                  <div className="search-box">
                    <input
                      className="form-control search"
                      placeholder="Search Communities"
                      onChange={handleChange}
                      value={search}
                    />
                    <span>
                      <i className="icon grey-search-icon" />
                    </span>
                  </div>
                </Col>
                <Col xs={6} md={3} xl={2} className="mob-right">
                  <div className="select-department">
                    <Select
                      options={areaOption}
                      placeholder={"All Area"}
                      isSearchable={false}
                      className={"category-select"}
                      classNamePrefix={"category-select"}
                      //   hideSelectedOptions={true}
                      value={selectedOption}
                      onChange={handleTabChange}
                      components={{
                        DropdownIndicator: () => (
                          <i className="icon grey-down-arrow"></i>
                        ),
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {/* <span>
                  <i className="icon grey-down-arrow" />
                </span> */}
                  </div>
                </Col>
                <Col xs={6} md={3} xl={2} className="mob-left">
                  <div className="select-department">
                    <Select
                      options={partOption}
                      placeholder={"Time Period"}
                      isSearchable={false}
                      className={"category-select"}
                      classNamePrefix={"category-select"}
                      //   hideSelectedOptions={true}
                      value={selectedOptionPart}
                      onChange={handlePartChange}
                      components={{
                        DropdownIndicator: () => (
                          <i className="icon grey-down-arrow"></i>
                        ),
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {/* <span>
                  <i className="icon grey-down-arrow" />
                </span> */}
                  </div>
                </Col>
              </Row>
            </ScrollAnimation>
          </div>
          <div className="marketreport-listing-section">
            {search
              ? currentItems?.map((data, index) => {
                  let processedImages
                  processedImages =
                    data?.node?.imagetransforms?.tail_image_Transforms ||
                    JSON.stringify({})
                  return (
                    <>
                      <div className="marketreport-list" key={data.node?.title}>
                        <div className="image-section">
                          <Link to={`${marketreportURL}${data.node?.slug}/`}>
                            {/* <ImageModule
                              ImageSrc={data.node?.tail_image}
                              altText={`${data.node?.title}`}
                              imagetransforms={processedImages}
                              renderer="pic-src"
                              imagename={imagename}
                              strapi_id={data?.node?.strapi_id}
                              classNames="img-fluid"
                            /> */}
                            <GGFXImage
                              ImageSrc={data.node?.tail_image}
                              altText={`${data.node?.title}`}
                              imagetransforms={data.node?.ggfx_results}
                              renderer="srcSet"
                              imagename={imagename}
                              strapiID={data.node?.strapi_id}
                            />
                          </Link>
                          {/* <div className="image-overlay">
                        <Link to={`${marketreportURL}${data.node.slug}/`}>
                          <span>
                            <i className="icon white-up-arrow" />
                          </span>
                        </Link>
                      </div> */}
                        </div>
                        <div className="content-section">
                          <Link to={`${marketreportURL}${data.node.slug}/`}>
                            <h5>{data.node?.title}</h5>
                            {data.node?.read_time && (
                              <p>{data.node?.read_time}</p>
                            )}
                          </Link>
                        </div>
                      </div>
                      {index === 7 &&
                        add_page_modules?.map((item, i) => {
                          return (
                            item?.__typename ===
                              "STRAPI__COMPONENT_COMPONENTS_BADGE_GREEN" && (
                              <GreenPropertyCardBadge isreportpage {...item} />
                            )
                          )
                        })}
                    </>
                  )
                })
              : currentItems?.map((data, index) => {
                  let processedImages
                  processedImages =
                    data?.node?.imagetransforms?.tail_image_Transforms ||
                    JSON.stringify({})

                  return (
                    <>
                      <ScrollAnimation
                        animateIn="animate__slideInUp"
                        animateOnce
                        delay={index * 100}
                        offset={100}
                      >
                        <div
                          className="marketreport-list"
                          key={data.node?.slug}
                        >
                          <div className="image-section">
                            <Link to={`${marketreportURL}${data.node?.slug}/`}>
                              {/* <ImageModule
                                ImageSrc={data.node?.tail_image}
                                altText={`${data.node?.title}`}
                                imagetransforms={processedImages}
                                renderer="pic-src"
                                imagename={imagename}
                                strapi_id={data?.node?.strapi_id}
                                classNames="img-fluid"
                              /> */}
                              <GGFXImage
                                ImageSrc={data.node?.tail_image}
                                altText={`${data.node?.title}`}
                                imagetransforms={data.node?.ggfx_results}
                                renderer="srcSet"
                                imagename={imagename}
                                strapiID={data.node?.strapi_id}
                              />
                            </Link>
                            {/* <div className="image-overlay">
                        <Link to={`${marketreportURL}${data.node.slug}/`}>
                          <span>
                            <i className="icon white-up-arrow" />
                          </span>
                        </Link>
                      </div> */}
                          </div>
                          <div className="content-section">
                            <Link to={`${marketreportURL}${data.node.slug}/`}>
                              <h5>{data.node?.title}</h5>
                              {data.node?.read_time && (
                                <p>{data.node?.read_time}</p>
                              )}
                            </Link>
                          </div>
                        </div>
                      </ScrollAnimation>
                      {index === 7 &&
                        add_page_modules?.map((item, i) => {
                          return (
                            item?.__typename ===
                              "STRAPI__COMPONENT_COMPONENTS_BADGE_GREEN" && (
                              <GreenPropertyCardBadge isreportpage {...item} />
                            )
                          )
                        })}
                    </>
                  )
                })}
          </div>

          <InnerPagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={selectedArea?.length}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      </div>
    </>
  )
}

export default MarketReportListing
